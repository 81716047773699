var site = site || {};

site.shopTheAd = site.shopTheAd || {};

(function ($) {
  site.shopTheAd = {
    /**
     * Get the Quickshop HTML and initialize the various internal components
     */
    render: function (args) {
      var self = this;

      return site.template.get({
        name: 'shoppable_ad_quickshop_v1',
        data: args.productData,
        callback: function (html) {
          $qs = $(html);
          self.setUpSkuSelection(args.productData, $qs);
          // Close button event handler
          $qs
            .find('.close')
            .once('qs-overlay-close')
            .click(function (e) {
              e.preventDefault();
              // add global trigger to be used for post-close functions
              $(document).trigger('qs-overlay-close', [{ quickshop: $qs }]);
            });

          return $qs;
        }
      });
    },
    setUpSkuSelection: function (productData, $qs) {
      // set up select dropdown & shade boxes if shaded
      this.setUpOtherOptions($qs);
    },
    setUpOtherOptions: function ($qs) {
      var $addBtn = $('.js-add-to-cart', $qs);

      if ($addBtn.length > 0) {
        prodcat.ui.addCartButton.updateInvStatus($addBtn);
        var $statusList = $('.js-inv-status-list', $qs);
        var skuBaseId = $statusList.data('sku-base-id');
        var skuData = prodcat.data.getSku(skuBaseId);

        if (!!skuData) {
          Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
        }
      }
    }
  };
})(jQuery);
